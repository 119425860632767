import React, { forwardRef, useRef, useImperativeHandle, FocusEvent } from 'react';
import { FieldType } from '../../utils/FormController';

interface InputRefObject {
    value: string;
}

interface InputProps {
    label?: string;
    validationMessage?: string;
    type?: FieldType.TEXT | FieldType.NUMBER | FieldType.EMAIL | FieldType.PASSWORD;
    value?: string | number;
    onChange?: Function;
    required?: boolean;
    placeholder?: string;
    readOnly?: boolean;
    disabled?: boolean;
    min?: number;
    max?: number;
    step?: number;
    style?: React.CSSProperties;
    labelMargin?: number;
}

const Input: React.RefForwardingComponent<InputRefObject, InputProps> = (
    { label, validationMessage, type, value, onChange, required, placeholder, readOnly, disabled, style, labelMargin },
    ref
) => {
    const inputRef = useRef(null);
    useImperativeHandle(ref, () => ({
        value: inputRef.current && inputRef.current.value,
    }));

    return (
        <label style={{ marginTop: typeof labelMargin === 'number' ? `${labelMargin}px` : undefined }}>
            {label}
            <input
                ref={inputRef}
                className={validationMessage ? 'invalid' : ''}
                onWheel={(e) => type === 'number' && e.currentTarget.blur()}
                type={type || FieldType.TEXT}
                value={value ?? ''}
                onChange={e => onChange(e.target.value)}
                required={required}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={disabled}
                style={style}
            />
            {validationMessage && <p className="error-message">{validationMessage}</p>}
        </label>
    );
};

export default forwardRef(Input);
